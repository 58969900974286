
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {

      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      let promise = store.dispatch(Actions.LOGIN, values);



      promise.then(({ data }) => {
        if (data.success) {
          store.commit(Mutations.SET_AUTH, data);
          let timerInterval
          Swal.fire({
            title: 'Giriş Başarılı',
            timer: 1000,
            confirmButtonText: "Tamam!",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          });
          setTimeout(() => {
            router.push({name: 'Main'});
          }, 1000);
        }
        else{
           Swal.fire({
              text: "Giriş Başarısız!",
              icon: "danger",
              buttonsStyling: false,
              confirmButtonText: "Tamam!",
              customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
              },
            });
        }
      })
      .catch(({ response }) => {
        store.commit(Mutations.SET_ERROR, response.data.errors);
      });
    

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
